import { api } from "./base";

export const getProducts = async({ locale, values }) => {
  console.log('values',values);
    api.defaults.headers = {
        locale,
        'Content-Type': 'application/json', // Ensure correct content type
    };

    const res = await api.post('/products', values); // Send values as an object
    return res.data;
};


export const getProduct = async({ locale, productId }) => {
    api.defaults.headers = { locale };
    const res = await api.get(`/product/${productId}`);
    return res.data;
};
export const searchProducts = async({ locale, values }) => {
    console.log({ values })
    api.defaults.headers = { locale };
    const res = await api.post(`/products`, values);
    return res.data;
};