import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { loadScript } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { clientUrl } from "../../../../api/base";
import { getSettings } from "../../../../api/settingsApi";
import { setSettings } from "../../../../store/settingsSlice";
import styles from "../../../../styles/Navbar.module.css";
import { saveCart } from "../../../../store/cartSlice";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";


function SectionHeader2() {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.value);
  const cart = useSelector((state) => state.cart);
  const { t, i18n } = useTranslation();

  const lang = i18n.language;
  const locale = useParams().lang;

  const location = useLocation();
  const { languages } = i18n;

  useEffect(() => {
    loadScript("js/sticky-header.js");
  });

  useEffect(() => {
    getSettings({ locale })
      .then((res) => {
        dispatch(setSettings(res.data.settings));
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  useEffect(() => {
    dispatch(saveCart());
  }, []);

  const handleToggleMenu = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (location.pathname.includes("/build")) {
      return navigate(`/${lang}`);
    }

    if (
      !location.pathname.includes(`/${languages[0]}`) &&
      !location.pathname.includes(`/${languages[1]}`)
    ) {
      return navigate(`/${lang}${location.pathname}`);
    } else {
      const newLang = location.pathname.split(`/`)[1];
      i18n.changeLanguage(newLang);
    }
  }, []);

  const handleLang = (locale) => {
    const oldLang = location.pathname.slice(0, 3);
    const newPath = location.pathname.split(oldLang)[1];
    i18n.changeLanguage(locale);
    const replaced = `${locale}${newPath}`;
    window.location.replace(clientUrl + "/" + replaced);
  };

  const handleActive = (path) => {
    if (location.pathname === path) {
      return styles.active;
    }
    return null;
  };

  const dir = lang === "en" ? "ltr" : "rtl";

  const handleNavigation = (type) => {
    // window.location.reload();
    navigate(`/${lang}/shop/products?type=${type}`, { replace: true });
    // window.location.reload();
    // window.location.reload();
  };

  return (
    <header
      className={
        "site-header header-style-2 topbar-transparent mobile-sider-drawer-menu " +
        (isActive ? "active" : "")
      }
      dir={dir}
    >
      <div className="sticky-header main-bar-wraper">
        <div
          className="main-bar"
          style={{
            background: "#0006",
          }}
        >
          <div className="container">
            <div className="logo-header">
              <NavLink to={`/${lang}`}>
              <FastImage
                proImage={settings?.logo}
                style={{ width: 70, height: 70 }} // Reduced size 4x smaller
                resizeMode="contain"
              />
              </NavLink>
            </div>
            {/* NAV Toggle Button */}
            <button
              id="mobile-side-drawer"
              data-target=".header-nav"
              data-toggle="collapse"
              type="button"
              className="navbar-toggler collapsed"
              onClick={handleToggleMenu}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar icon-bar-first" />
              <span className="icon-bar icon-bar-two" />
              <span className="icon-bar icon-bar-three" />
            </button>

            {/* MAIN Vav */}
            <div dir={dir} className="header-nav navbar-collapse ">
              <ul className=" nav navbar-nav">
                <li className="active">
                  <NavLink
                    to={`/${lang}`}
                    className={`${handleActive(`/${lang}`)}`}
                  >
                    {t("home")}
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    className={`${handleActive(`/${lang}/shop/products`)}`}
                    to={`/${lang}/shop/products`}
                  >
                    {t("products")}
                  </NavLink>
                </li> */}<li>
                  <NavLink
                    className={`${handleActive(`/${lang}/about-us`)}`}
                    to={`/${lang}/about-us`}
                  >
                    {t("about")}
                  </NavLink>
                </li>

            
<li>
        <a onClick={() => handleNavigation('tractor_unit')}>
          {t("Trucks")}
        </a>
      </li>
      <li>
        <a onClick={() => handleNavigation('trailer')}>
          {t("Trailers-and-cranes")}
        </a>
      </li>
      <li>
        <a onClick={() => handleNavigation('spareParts')}>
          {t("Spare-parts")}
        </a>
      </li>
                {/* <li>
                  <NavLink
                    className={`${handleActive(`/${lang}/companies`)}`}
                    to={`/${lang}/companies`}
                  >
                    {t("companies")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`${handleActive(`/${lang}/services`)}`}
                    to={`/${lang}/services`}
                  >
                    {t("our_services")}
                  </NavLink>
                </li> */}
                
                <li>
                  <NavLink
                    className={`${handleActive(`/${lang}/contact-us1`)}`}
                    to={`/${lang}/contact-us1`}
                  >
                    {t("contact_us")}
                  </NavLink>
                </li>
                <li onClick={() => handleLang(lang === "en" ? "ar" : "en")}>
                  <NavLink>{lang === "en" ? "عربي" : "En"}</NavLink>
                </li>
                {/* <li
                  style={{
                    position: "relative",
                  }}
                >
                  <NavLink
                    className={`${handleActive(`/${lang}/shop/cart`)}`}
                    to={`/${lang}/shop/cart`}
                  >
                    <i
                      className="fa fa-cart-plus mt-icon-box"
                      style={{ fontSize: 18 }}
                    ></i>
                    <ShoppingCartOutlinedIcon />
                  </NavLink>
                  {!!cart.totalQty && (
                    <div className="cart-badge">{cart.totalQty}</div>
                  )}
                </li> */}
                {/* {settings?.whatsapp?.length
                        ? settings?.whatsapp[0]?.contact
                        : null} */}
                {settings && settings.whatsapp && settings.whatsapp.length && (
                  <li>
                    <a
                      href={"https://wa.me/" + settings?.whatsapp[0]?.contact}
                      target="_blank"
                    >
                      <WhatsAppIcon />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default SectionHeader2;
