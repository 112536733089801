import { Fragment, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getProducts } from "../../../api/productsApi";
import { useTranslation } from "react-i18next";
import SectionBanner from "../../sections/common/banner/banner";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../store/cartSlice";
import Loader from "../Loader";
import FilterProducts from "../FilterProducts_2";
import { CircularProgress } from "@mui/material";
import { route } from "../../../globals/constants";
import FastImage from "../../../globals/elements/fastimg";
import { NavLink } from "react-router-dom";
import { reloadResources } from "i18next";

function ShopProductsPage() {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.value);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("false");
  const [products, setProducts] = useState([]);
  const [prices, setPrices] = useState([1000, 120000]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [values, setValues] = useState({
    title: "",
    mileage: "",
    category_id: [],
    suspensionType: [],
    kind: [],
    gearBox: [],
    configuration: [],
    minPrice: prices[0],
    maxPrice: prices[1],
    date: selectedDate
  });
  const { lang } = useParams();
  const locale = lang;
  const { t } = useTranslation();
  const [text, setText] = useState(
    lang === "en"
      ? "No result for such type of product"
      : "نأسف لعدم وجود منتجات من هذا النوع"
  );

  const dir = lang === "en" ? "ltr" : "rtl";
  const location = useLocation();

  // State for title and description
  const [currentTitle, setCurrentTitle] = useState("Our Products");
  const [currentDescription, setCurrentDescription] = useState("");

  // Function to get query parameters
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      type: params.get("type"),
    };
  };

  const { type: urlType } = getQueryParams();

  // Mapping between kind and backend section identifiers
  const kindToSectionMap = {
    "tractor_unit": "tractorUnits", // Update this if the backend has a corresponding section
    "trailer": "trailersCranes",
    "spareParts": "spareParts"
  };
  const filterProductsFetch = () => { 
    setLoaded(true);
  
    // Convert arrays to comma-separated strings
    const formattedValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [
        key,
        Array.isArray(value) ? value.join(",") : value, // Convert arrays to strings
      ])
    );
  
    getProducts({ locale, values: formattedValues }) // Send formatted values
      .then((res) => {
        console.log(res.data);
        setProducts(res.data.products);
  
        // Extract sections from the response
        const sections = res.data;
  
        // Get the kind from the values state
        const kind = values.kind?.[0]; // Safely access kind array
  
        // Get the backend identifier from the kind
        const backendIdentifier = kindToSectionMap[kind];
  
        // Find the matched section using the backend identifier
        const matchedSection = Object.values(sections).find(
          (section) => section.identifier === backendIdentifier
        );
  
        if (matchedSection) {
          setCurrentTitle(matchedSection.title);
          setCurrentDescription(matchedSection.description);
        } else {
          // Fallback if no section is matched
          setCurrentTitle("Our Products");
          setCurrentDescription("");
        }
  
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching products:", err);
        setLoading(false);
      });
  };
  

  useEffect(() => {
    const { type } = getQueryParams();
    if (type) {
      setValues((prevValues) => ({
        ...prevValues,
        kind: [type], // Set the kind based on the query parameter
      }));
    }
  }, [location.search]);

  useEffect(() => {
    filterProductsFetch();
  }, [values.category_id, values.title, values.mileage, values.suspensionType, values.kind, values.configuration, values.gearBox]);

  const updateProducts = (products) => {
    window.location.reload();
    setProducts(products);
  };

  const handleType = (result) => {
    setType(result);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleAddToCart = (product) => {
    dispatch(addToCart({ product, qty: 1 }));
  };

  if (loading) {
    return <Loader />;
  }

  const generateProducts = () => {
    return products?.map((product) => {
      return (
        <div
          key={product.id}
          style={{ maxWidth: "18rem" }}
          className="col-lg-4 col-md-6 col-xs-100pc m-b30"
        >
          <div className="mt-box mt-product-box bdr-1 bdr-solid bdr-gray-light">
            <div className="mt-thum-bx mt-img-overlay4 mt-img-effect zoom">
              <FastImage
                proImage={product.image && product.image}
                src={`${
                  product.image ? product.image : "images/products/pic-1.jpg"
                }`}
                alt={product.title}
              />
              <div className="overlay-bx">
                <div className="overlay-icon d-flex justify-content-center align-items-center gap-3 text-white">
                  {/* Add to cart button or other actions */}
                </div>
              </div>
            </div>
            <div className="mt-info text-center">
              <div className="p-a10">
                {product?.title != null &&
                <h4 className="mt-title">
                  <NavLink
                    to={`/${lang}/shop/detail/${product.id}/category/${product.category_id}`}
                  >
                    {product?.title?.substring(0, 20)}...
                  </NavLink>
                </h4>}
                <span className={settings.used_car ? '' : 'd-none'}>
                  <p className={product.used_car ? 'text-danger text-bold' : 'text-success text-bold'}>
                    {product.used_car ? 'Used' : 'New'}
                  </p>
                </span>
                <div className="pt-2">
                  <NavLink
                    className="site-button"
                    to={`/${lang}/shop/detail/${product.id}/category/${product.category_id}`}
                  >
                    {t("show_more")}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const currentpath = {
    crumb: t("our_products"),
    title: currentTitle,
    description: currentDescription
  };

  return (
    <Fragment>
      <SectionBanner data={currentpath} />

      {/* Our Product */}
      {loaded ? (
        <div dir={dir} className="section-full p-t80">
          <div className="container-fluid">
            <div className="section-content">
              {/* TITLE START */}
              <div className="section-head text-center">
                <h2 data-title={"Products"}>{currentTitle}</h2>
                <div className="mt-separator-outer m-b30">
                  <div className="mt-separator site-bg-primary" />
                </div>
                <p>{currentDescription}</p>
              </div>
              {/* TITLE END */}
              <div className="row d-flex justify-content-center">
                <div className="col-sm-12 col-md-3 col-lg-3">
                  {/* <FilterProducts
                    setType={handleType}
                    updateProducts={updateProducts}
                    filterProducts={filterProductsFetch}
                    values={values}
                    setValues={setValues}
                    prices={prices}
                    setPrices={setPrices}
                    handleDateChange={handleDateChange}
                  /> */}
                </div>
                <div className="col-sm-12 col-md-9 col-lg-9">
                  <div className="row">{generateProducts()}</div>
                  {type === "error" && <h3 dir={dir}>{text}</h3>}
                  {type === "loading" && <CircularProgress color="success" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Fragment>
  );
}

export default ShopProductsPage;