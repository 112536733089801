import { Fragment, useEffect, useState } from "react";
import { route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { NavLink } from "react-router-dom";
import { getProducts } from "../../../../api/productsApi";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { postNewsletter } from "../../../../api/newsletterApi";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function SectionFooter1(props) {
  const settings = useSelector((state) => state.settings.value);
  const [loaded, setLoaded] = useState(false);
  const [products, setProducts] = useState([]);
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const locale = lang;
  const dir = lang === "en" ? "ltr" : "rtl";

  useEffect(() => {
    getProducts({ locale })
      .then((res) => {
        setLoaded(true);
        setProducts(res.data.products?.slice(0, 3) || []);
      })
      .catch((err) => console.log({ err }));
  }, []);

  const handleNewsletter = (e) => {
    e.preventDefault();
    postNewsletter(email)
      .then(() => {
        setMessage(lang === "en" ? "Success" : "تم بنجاح");
        setSuccess(true);
      })
      .catch((err) => console.log({ err }));
  };

  const displayProducts = () => {
    return products.map((product) => (
      <div dir={dir} key={product.id} className="widget-post clearfix">
        <div className="mt-post-media text-white overflow-hide">
          <FastImage proImage={product.image} alt="" />
        </div>
        <div className="mt-post-info">
          <div className="mt-post-header">
            <div className="post-title">
              <NavLink to={route.blog.POST_SINGLE}>{product.title}</NavLink>
            </div>
          </div>
          <div className="mt-post-meta">
            <ul>
              <li className="post-author">
                <i className="fa fa-user site-text-primary" />
                By Admin
              </li>
              <li className="post-comment">
                <i className="fa fa-comments site-text-primary" /> 28
              </li>
            </ul>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <footer dir={dir} className={`site-footer ${props.theme}`}>
      {/* Footer Top */}
      <div className="footer-top overlay-wraper">
        <div className="overlay-main" />
        <div className="container">
          <div className="row">
            {/* About */}
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_about">
                {settings?.title && <h4 className="widget-title">{settings.title}</h4>}
                <div className="logo-footer clearfix p-b15">
                  <NavLink to={route.home.HOME1}>
                    <FastImage 
                      proImage={settings?.white_logo} 
                      alt="Logo" 
                      style={{ height: "130px", width: "auto" }} // Set height and maintain aspect ratio
                    />
                  </NavLink>
                </div>
                {settings?.description && (
  <p
    dangerouslySetInnerHTML={{
      __html: settings?.description,
    }}
  ></p>
)}
                <ul>
                  
                  {settings?.phones?.[0]?.contact && (
                    <li>
                      <i className="fa fa-phone site-text-primary" />
                      <b className="p-lr5 text-uppercase">{t("phone")} :</b>
                      <span dir="ltr">{settings.phones[0].contact}</span>
                      {settings?.phones?.[1]?.contact && (
                        <span> | {settings.phones[1].contact}</span>
                      )}
                    </li>
                  )}
                  {settings?.emails?.[0]?.contact && (
                    <li>
                      <i className="fa fa-envelope site-text-primary" />
                      <b className="p-lr5 text-uppercase">{t("email")} :</b>
                      {settings.emails[0].contact}
                    </li>
                  )}
                  {settings?.address1 && (
                    <li>
                      <i className="fa fa-location-arrow site-text-primary" />
                      <b className="p-lr5 text-uppercase">{t("location")} :</b>
                      {settings.address1}
                    </li>
                  )}
                  {settings?.whatsapp?.[0]?.contact && (
                    <li>
                      <a
                        href={`https://wa.me/${settings.whatsapp[0].contact}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <WhatsAppIcon /> <b className="p-lr5 text-uppercase">{settings.whatsapp[0].contact}</b>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            {/* Useful Links */}
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_services">
                <h4 className="widget-title">{t("useful_links")}</h4>
                <ul>
                  <li><NavLink to={`/${lang}/index`}>{t("home")}</NavLink></li>
                  <li><NavLink to={`/${lang}/about-us`}>{t("about")}</NavLink></li>
                  <li><NavLink to={`/${lang}/services`}>{t("our_services")}</NavLink></li>
                  <li><NavLink to={`/${lang}/shop/products`}>{t("products")}</NavLink></li>
                  <li><NavLink to={`/${lang}/contact-us1`}>{t("contact_us")}</NavLink></li>
                </ul>
              </div>
            </div>
            {/* Recent Post */}
            <div className="col-lg-3 col-md-6">
              <div className="widget recent-posts-entry-date">
                <h4 className="widget-title">{t("recent_posts")}</h4>
                <div className="widget-post-bx">
                  {loaded ? <Fragment>{displayProducts()}</Fragment> : <p>Loading...</p>}
                </div>
              </div>
            </div>
            {/* Newsletter */}
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_newsletter f-news-letter">
                <h4 className="widget-title">{t("news_letter")}</h4>
                <div className="newsletter-bx m-b30">
                  <form dir="ltr" onSubmit={handleNewsletter}>
                    <div className="input-group">
                      <input
                        name="email"
                        className="form-control"
                        placeholder={t("enter_email")}
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {success && (
                        <div className="alert alert-primary" role="alert">
                          {message}
                        </div>
                      )}
                      <span className="input-group-btn">
                        <button type="submit" className="site-button btn font-weight-600 d-flex align-items-center justify-content-center">
                          <i className="fa fa-paper-plane" />
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Bottom */}
      <div className="footer-bottom overlay-wraper">
        <div className="overlay-main" />
        <div className="container p-t30">
          <div className="mt-footer-bot-center">
            {settings?.copyright && (
              <span className="copyrights-text">{settings.copyright}</span>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default SectionFooter1;
